export const images = {
    gocard: {
        zaloImage: 'https://img.dangky.info/gocard/zalo-image.webp',
        logoFooter: 'https://img.dangky.info/gocard/icons/logo-footer-1.svg',
        logo: 'https://img.dangky.info/gocard/icons/logo.png',
        logoHeader: 'https://img.dangky.info/gocard/icons/logo-header.svg',
        cardIcon: 'https://img.dangky.info/gocard/icons/card.svg',
        loanIcon: 'https://img.dangky.info/gocard/icons/loan.svg',
        banner: 'https://img.dangky.info/gocard/banner.webp',
        maskGroup1: 'https://img.dangky.info/gocard/mask-group-1.webp',
        maskGroup2: 'https://img.dangky.info/gocard/mask-group-2.webp',
        maskGroup: 'https://img.dangky.info/gocard/mask-group.webp',
        icon1: 'https://img.dangky.info/finzoo/icons/icon-1.svg',
        icon2: 'https://img.dangky.info/finzoo/icons/icon-2.svg',
        icon3: 'https://img.dangky.info/finzoo/icons/icon-3.svg',
        success: 'https://img.dangky.info/gocard/success.png',
        logoSuccess: 'https://img.dangky.info/gocard/icons/logo1.png',
        successImage: 'https://img.dangky.info/gocard/success-img.webp',
        tnexFinanceIcon: 'https://img.dangky.info/gocard/icons/logo-tnex-finance-1.svg',
        loanTimeIcon: 'https://img.dangky.info/gocard/icons/loan-time.svg',
        loanLimitIcon: 'https://img.dangky.info/gocard/icons/loan-limit.svg',
        tiktokImg: 'https://img.dangky.info/gocard/tiktok-image.png',
    },
    tnex: {
        logoHeader: 'https://img.dangky.info/gocard/icons/logo-tnex-gocard.svg',
        tnexBanner: 'https://img.dangky.info/gocard/Tnex-banner.png',
        stepRegisterLoan1: 'https://img.dangky.info/gocard/stepRegisterLoan/Picture1.png',
        stepRegisterLoan2: 'https://img.dangky.info/gocard/stepRegisterLoan/Picture2.png',
        stepRegisterLoan3: 'https://img.dangky.info/gocard/stepRegisterLoan/Picture3.png',
        stepRegisterLoan4: 'https://img.dangky.info/gocard/stepRegisterLoan/Picture4.png',
        stepRegisterLoan5: 'https://img.dangky.info/gocard/stepRegisterLoan/Picture5.png',
    },
    vpbank: {
        logoHeader: 'https://img.dangky.info/gocard/icons/vpbank-gocard.svg',
        bannerVP: 'https://img.dangky.info/gocard/VpBank/banner-vpbank.webp',
    },
    vib: {
        logoHeader: 'https://img.dangky.info/gocard/icons/vib-gocard.svg',
        bannerVIB: 'https://img.dangky.info/gocard/banner-vib.png'
    },
    tpbank: {
        logoHeader: 'https://img.dangky.info/gocard/icons/logo-tpbank-evo.svg'
    },
    partner: {
        homeCredit: 'https://img.dangky.info/gocard/Partner/partner-home-credit.svg',
        lotte: 'https://img.dangky.info/gocard/Partner/partner-lotte.svg',
        tnex: 'https://img.dangky.info/gocard/Partner/partner-tnex.svg',
        tpBank: 'https://img.dangky.info/gocard/Partner/partner-tpbank.svg',
        vib: 'https://img.dangky.info/gocard/Partner/partner-vib.svg',
    }
}
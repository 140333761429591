import arrowRight from '@assets/icon/arrow-right.svg';
import { images } from "@assets/images";
import React, {useEffect, useState} from "react";
import "./AppFooter.scss";

const AppFooter = () => {

    const handleClick = () => {
        window.location.href = "https://www.tiktok.com/@gocardtuvantaichinh";
    };
    const [hideFooter, setHideFooter] = useState(false);
    useEffect(() => {
        if(window.location.href.indexOf("partner")>0 || sessionStorage.getItem("hide-footer")){
            sessionStorage.setItem("hide-footer",'true')
            setHideFooter(true);
        }
    }, []);

    return (
        <>
            <footer id="footer" className="footer"
                    style={{boxShadow: 'none', background: '#fff', marginTop: '50px'}}>
                <div className="zalo-img">
                    <div className="background-image">
                        <img src={images.gocard.tiktokImg} alt="tiktok-image"/>
                    </div>
                    <div className="tiktok-card">
                        <span className="tiktok-text">
                            Theo dõi kênh Tiktok của chúng tôi để cập nhật những tin tức mới nhất!
                        </span>
                        <button className="tiktok-button" onClick={handleClick}>
                            Tham gia ngay
                            <img src={arrowRight} alt="arrow-right" className="icon-arrow-right"/>
                        </button>
                    </div>
                </div>
                <div className={hideFooter? 'inner-footer hide-component' : 'inner-footer'}>
                    <div className="row justify-content-center">
                        <div className="row">
                            <a style={{marginBottom: "-10px"}} href="">
                                <img className="logo-footer" src={images.gocard.logoFooter} alt="logo-footer"/>
                            </a>
                            <div className="org-info">
                                <p><label>Đơn vị chủ quản</label>: Công ty CP Công nghệ và Truyền thông Dagoras</p>
                                <p><label>Trụ sở</label>: Tầng 8, Tòa nhà HT Building, Số 80 Duy Tân, Phường Dịch Vọng
                                    Hậu, Quận Cầu Giấy, Thành phố Hà Nội</p>
                            </div>
                            <div className="text-list">
                                <div className="text-info">
                                    <span>Thông tin</span>
                                    <ul>
                                        <li><a href="/gioi-thieu">Giới thiệu</a></li>
                                        <li><a href="/contact">Liên hệ</a></li>
                                        <li><a href="/dieu-khoan-su-dung">Điều khoản</a></li>
                                    </ul>
                                </div>
                                <div className="text-info-product">
                                    <span>Thông tin sản phẩm</span>
                                    <ul>
                                        <li><a href="/loan">Vay tiêu dùng</a></li>
                                        <li><a href="/card">Thẻ tín dụng</a></li>
                                        <li><a href="/blog">Blogs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="org-info">
                                <p>Gocard không phải đơn vị cung cấp cho vay và không phát hành các khoản vay. Dịch vụ
                                    của Gocard giúp đánh giá các đối tác vay uy tín với các sản phẩm tài chính đa dạng,
                                    thời gian trả nợ linh hoạt từ 91 đến 180 ngày, với lãi suất APR tối thiểu là 0% và
                                    tối đa là 20%. Gocard không tính phí sử dụng dịch vụ. Chi phí cuối cùng mà người vay
                                    phải trả phụ thuộc vào từng khoản vay. Người dùng sẽ nhận được thông tin đầy đủ và
                                    chính xác về APR, cũng như tất cả các khoản phí trước khi ký hợp đồng vay.</p>
                                <p><label>Giấy phép hoạt động</label>: Giấy chứng nhận Đăng ký Kinh doanh số 0109236860
                                    cấp bởi Sở Kế hoạch và Đầu tư TP Hà Nội ngày 2020-06-24</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="copyright"> */}
                    {/*<p data-size="xs" */}
                    {/*   style={{color: "white"}}*/}
                    {/*   className="wIj6fkDcopyright-certificate">Bản quyền thuộc Công ty Cổ phần Tài chính Công*/}
                    {/*    nghệ Dagoras<br/>*/}
                    {/*    Địa chỉ: Tầng 6, Tòa nhà HT Building, Số 80 Duy Tân, Phường Dịch Vọng Hậu, Quận Cầu*/}
                    {/*    Giấy, Thành phố Hà Nội <br/>*/}
                    {/*    MST: 0110217943 - GPDKKD do Phòng Đăng Ký Kinh Doanh – Sở Kế Hoạch và Đầu Tư TP. Hà Nội*/}
                    {/*    cấp ngày 29/12/2022</p>*/}
                    {/* <span data-size="xs"
                                  style={{color: "white", fontSize: "1rem"}}
                                  className="wIj6fkDcopyright-certificate">Copyright ©2023</span> */}
                    {/* </div> */}
                </div>
            </footer>
        </>
    )
}
export default React.memo(AppFooter);

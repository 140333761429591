import React, {useEffect, useState} from 'react';
import './PersonalInfoPageView.scss';
import cities from '../../../utils/city.json';
import districts from '../../../utils/district.json';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {notification, Spin} from 'antd';
import Select, {SingleValue} from 'react-select';
import publicService from 'src/app/service/PublicService';
import {callReceiver} from 'src/app/service/SendDataToPartner';

interface RouteParams {
    type: string;
}

interface LocationState {
    phone: string;
    emailUsername: string;
    emailDomain: string;
    partner?: string;
}

interface CreditCard {
    cashAdvanceFee: any;
    id: number;
    name: string;
    imageUrl: string;
    creditLimitFrom: number;
    creditLimitTo: number;
    annualFee: number;
    monthlyIncome: number;
    partner: string;
    requiredAge: number;
    features: string;
    interestRate: string;
    navigateLink: string;
}

declare global {
    interface Window {
        BM: any;
    }
}

const PersonalInfoPageView = () => {

    const {type} = useParams<RouteParams>();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {phone, emailUsername, emailDomain, partner} = location.state;
    const [errorNotify, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const storedLink = sessionStorage.getItem('link') || '';
    const hasOtpSub = storedLink.includes('sub=otp');

    const [contactInfo, setContactInfo] = useState({
        dob: '2000-01-01',
        selectedCity: '',
        cityCode: '',
        selectedDistrict: null,
        inputAddress: '',
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://apicampaign.bizfly.vn/js/sdk.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.BM) {
                window.BM.init({
                    project_token: '70963d3f-220d-449a-8014-35fe11e8fdb2',
                    app_key: 'ad53d774-dfdc-4b4a-a6c3-c62594b62904',
                    version: 'v3.0'
                });
            } else {
                console.error('BizMail SDK not loaded correctly, window.BM is undefined.');
            }
        };
        script.onerror = () => {
            console.error('Failed to load BizMail SDK script.');
        };
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        document.title = "Gocard | Nhập thông tin cá nhân"
    }, []);

    const handleCityChange = (selectedOption: SingleValue<any>) => {
        setContactInfo({
            ...contactInfo,
            selectedCity: selectedOption?.value || '',
            cityCode: selectedOption?.code || '',
            selectedDistrict: null,
        });
    };

    const handleDistrictChange = (selectedOption: any) => {
        setContactInfo({
            ...contactInfo,
            selectedDistrict: selectedOption?.value || null,
        });
    };

    // const onChange = (date: Dayjs | null, dateString: string) => {
    //     setContactInfo(prevContactInfo => ({
    //         ...prevContactInfo,
    //         dob: date ? dateString : '',
    //     }));
    // };

    const handleDateChange = (e: any) => {
        const value = e.target.value;
        setContactInfo({ ...contactInfo, dob: value });
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setContactInfo({
            ...contactInfo,
            inputAddress: value,
        })
    }

    // const handleSubmit = async () => {
    //     const {
    //         inputName,
    //         inputCccd,
    //         selectedCity,
    //         selectedDistrict,
    //         validName,
    //         validCccd,
    //         validSelectedCity,
    //         validSelectedDistrict
    //     } = contactInfo;
    //
    //     // Validation checks
    //     if (!validName || !validCccd || !validSelectedCity || !validSelectedDistrict) {
    //         return;
    //     }
    //
    //     if (inputName.trim() === '' || inputCccd.trim() === '' || selectedCity === '' || selectedDistrict === null) {
    //         if (inputName.trim() === '') {
    //             setContactInfo(prevState => ({
    //                 ...prevState,
    //                 validName: false,
    //             }));
    //         }
    //         if (inputCccd.trim() === '') {
    //             setContactInfo(prevState => ({
    //                 ...prevState,
    //                 validCccd: false,
    //             }));
    //         }
    //         if (selectedCity === '') {
    //             setContactInfo(prevState => ({
    //                 ...prevState,
    //                 validSelectedCity: false,
    //             }));
    //         }
    //         if (selectedDistrict === null) {
    //             setContactInfo(prevState => ({
    //                 ...prevState,
    //                 validSelectedDistrict: false,
    //             }));
    //         }
    //         return;
    //     }
    //
    //     if (window.BM) {
    //         const email = sessionStorage.getItem("userEmail")
    //         window.BM.importAutomation({
    //             uuid: '75c185d6-3cb5-4a08-8d82-5727e3c9b055',
    //             source: 'source-name',
    //             subscriber: {
    //                 name: 'Tên người đăng ký',
    //                 emails: email,
    //                 phone: 'số điện thoại'
    //             }
    //         });
    //     }
    //
    //     const leadDto = {
    //         fullName: inputName,
    //         identityNumber: inputCccd,
    //         city: selectedCity,
    //         district: selectedDistrict,
    //         byPassOtp: hasOtpSub
    //     };
    //
    //     const existingLoanInfo = sessionStorage.getItem('loanInfo');
    //
    //     // Nếu có loanInfo rồi thì tiếp tục lưu vào sessionStorage
    //     const loanInfo = existingLoanInfo ? JSON.parse(existingLoanInfo) : {};
    //     loanInfo.identityNumber = inputCccd;
    //     loanInfo.fullName = inputName;
    //     sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));
    //
    //     const storedCardData = sessionStorage.getItem('cardData');
    //     let step = 'nhap-thong-tin-ca-nhan';
    //
    //     if (storedCardData) {
    //         const cardData = JSON.parse(storedCardData) as CreditCard;
    //
    //         if (cardData.partner) {
    //             step = 'nhap-thong-tin-merchant';
    //
    //             Object.assign(leadDto, {
    //                 product: cardData.name,
    //                 creditLimit: cardData.creditLimitTo,
    //                 monthlyIncome: cardData.monthlyIncome,
    //                 partner: cardData.partner,
    //             });
    //
    //             setLoading(true);
    //             try {
    //                 const UUID = sessionStorage.getItem('user_id');
    //
    //                 try {
    //                     await Promise.all([
    //                         publicService.saveLeadProduct({
    //                             name: cardData.name,
    //                             creditLimitTo: cardData.creditLimitTo,
    //                             creditLimitFrom: cardData.creditLimitFrom,
    //                             annualFee: cardData.annualFee,
    //                             cashAdvanceFee: cardData.cashAdvanceFee,
    //                             monthlyIncome: cardData.monthlyIncome,
    //                             requiredAge: cardData.requiredAge,
    //                             features: cardData.features,
    //                             interestRate: cardData.interestRate,
    //                             uuid: UUID,
    //                         }),
    //                         publicService.applyRegister(step, type, leadDto)
    //                     ]);
    //
    //                     console.log("Cả hai hàm đã được thực thi thành công");
    //                 } catch (error) {
    //                     console.error("Có lỗi xảy ra khi thực thi:", error);
    //                 }
    //
    //                 history.push('/success');
    //
    //                 return;
    //             } catch (error: any) {
    //                 errorNotify.error({
    //                     message: 'Lỗi',
    //                     description: error.response?.data || 'An error occurred',
    //                 });
    //             } finally {
    //                 setLoading(false);
    //             }
    //         }
    //     }
    //
    //     setLoading(true);
    //     try {
    //         const resp = await publicService.applyRegister(step, type, leadDto);
    //
    //         if (resp.status === 200) {
    //             if (type === 'credit-card') {
    //                 history.push('/danh-sach-the', {listCard: resp.data.creditCards, filterType: resp.data.filterType});
    //             } else {
    //                 sessionStorage.removeItem('cardData');
    //
    //                 await callReceiver();
    //
    //                 const selectedLoanPackage = JSON.parse(sessionStorage.getItem('selectedLoanPackage') || '{}');
    //                 const selectedLoanData = JSON.parse(sessionStorage.getItem('loanData') || '{}');
    //                 const userUUID = sessionStorage.getItem('user_id');
    //
    //                 const loanData = {
    //                     ...selectedLoanPackage,
    //                     ...selectedLoanData,
    //                     uuid: userUUID,
    //                 };
    //
    //                 const response = await publicService.saveLeadProduct(loanData);
    //
    //                 if (response.status === 200) {
    //                     history.push('/success-view');
    //                 }
    //             }
    //         }
    //     } catch (error: any) {
    //         errorNotify.error({
    //             message: 'Lỗi',
    //             description: error.response?.data || 'An error occurred',
    //         });
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleSubmit = async () => {
        const {
            dob,
            selectedCity,
            selectedDistrict,
            inputAddress,
        } = contactInfo;

        const formattedDate = dob.split('-').reverse().join('-');

        if (window.BM) {
            const email = sessionStorage.getItem("userEmail")
            window.BM.importAutomation({
                uuid: '75c185d6-3cb5-4a08-8d82-5727e3c9b055',
                source: 'source-name',
                subscriber: {
                    name: 'Tên người đăng ký',
                    emails: email,
                    phone: 'số điện thoại'
                }
            });
        }

        const leadDto = {
            dob: formattedDate,
            address: inputAddress,
            city: selectedCity,
            district: selectedDistrict,
        };

        const storedCardData = sessionStorage.getItem('cardData');
        let step = 'nhap-thong-tin-ca-nhan';

        if (storedCardData) {
            const cardData = JSON.parse(storedCardData) as CreditCard;

            if (partner) {
                step = 'nhap-thong-tin-merchant';

                Object.assign(leadDto, {
                    product: cardData.name,
                    creditLimit: cardData.creditLimitTo,
                    monthlyIncome: cardData.monthlyIncome,
                    partner: cardData.partner,
                });

                setLoading(true);
                try {
                    const UUID = sessionStorage.getItem('user_id');

                    try {
                        await Promise.all([
                            publicService.saveLeadProduct({
                                name: cardData.name,
                                creditLimitTo: cardData.creditLimitTo,
                                creditLimitFrom: cardData.creditLimitFrom,
                                annualFee: cardData.annualFee,
                                cashAdvanceFee: cardData.cashAdvanceFee,
                                monthlyIncome: cardData.monthlyIncome,
                                requiredAge: cardData.requiredAge,
                                features: cardData.features,
                                interestRate: cardData.interestRate,
                                uuid: UUID,
                            }),
                            publicService.applyRegister(step, type, leadDto)
                        ]);

                        console.log("Cả hai hàm đã được thực thi thành công");
                    } catch (error) {
                        console.error("Có lỗi xảy ra khi thực thi:", error);
                    }

                    if (hasOtpSub) {
                        history.push({
                            pathname: `/success`,
                            state: {partner: partner},
                            search: `?partner=${partner}`,
                        });
                    } else {
                        history.push({
                            pathname: `/receive-OTP/${type}`,
                            state: {
                                cardData: cardData,
                                phone: phone,
                                emailUsername: emailUsername,
                                emailDomain: emailDomain,
                                partner: partner
                            },
                            search: `?partner=${partner}`,
                        });
                    }

                    return;
                } catch (error: any) {
                    errorNotify.error({
                        message: 'Lỗi',
                        description: error.response?.data || 'An error occurred',
                    });
                } finally {
                    setLoading(false);
                }
            }
        }

        setLoading(true);
        try {
            const resp = await publicService.applyRegister(step, type, leadDto);

            if (resp.status === 200) {
                if (type === 'credit-card') {
                    history.push(`/danh-sach-the/${type}`, {
                        listCard: resp.data.creditCards,
                        filterType: resp.data.filterType,
                        phone: phone,
                        emailUsername: emailUsername,
                        emailDomain: emailDomain,
                    });
                } else {
                    sessionStorage.removeItem('cardData');

                    await callReceiver();

                    const selectedLoanPackage = JSON.parse(sessionStorage.getItem('selectedLoanPackage') || '{}');
                    const loanPackage = JSON.parse(sessionStorage.getItem('loanPackage') || '{}');
                    const selectedLoanData = JSON.parse(sessionStorage.getItem('loanData') || '{}');
                    const userUUID = sessionStorage.getItem('user_id');

                    const loanData = {
                        ...selectedLoanPackage,
                        ...loanPackage,
                        ...selectedLoanData,
                        uuid: userUUID,
                    };

                    const response = await publicService.saveLeadProduct(loanData);

                    if (response.status === 200) {
                        if (hasOtpSub) {
                            if (partner) {
                                history.push({
                                    pathname: '/success-view',
                                    state: {loanData: loanData},
                                    search: `?partner=${partner}`,
                                });
                            } else {
                                history.push('/success-view', {loanData: loanData});
                            }
                        } else {
                            if (partner) {
                                history.push({
                                    pathname: `/receive-OTP/${type}`,
                                    state: {
                                        loanData: loanData,
                                        phone: phone,
                                        emailUsername: emailUsername,
                                        emailDomain: emailDomain,
                                    },
                                    search: `?partner=${partner}`,
                                });
                            } else {
                                history.push(`/receive-OTP/${type}`,
                                    {
                                        loanData: loanData,
                                        phone: phone,
                                        emailUsername: emailUsername,
                                        emailDomain: emailDomain,
                                    });
                            }
                        }
                    }
                }
            }
        } catch (error: any) {
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitSkip = async () => {
        if (window.BM) {
            const email = sessionStorage.getItem("userEmail")
            window.BM.importAutomation({
                uuid: '75c185d6-3cb5-4a08-8d82-5727e3c9b055',
                source: 'source-name',
                subscriber: {
                    name: 'Tên người đăng ký',
                    emails: email,
                    phone: 'số điện thoại'
                }
            });
        }

        const leadDto = {
            dob: '',
            address: '',
            city: '',
            district: '',
        };

        const storedCardData = sessionStorage.getItem('cardData');
        let step = 'nhap-thong-tin-ca-nhan';

        if (storedCardData) {
            const cardData = JSON.parse(storedCardData) as CreditCard;

            if (partner) {
                step = 'nhap-thong-tin-merchant';

                Object.assign(leadDto, {
                    product: cardData.name,
                    creditLimit: cardData.creditLimitTo,
                    monthlyIncome: cardData.monthlyIncome,
                    partner: cardData.partner,
                });

                setLoading(true);
                try {
                    const UUID = sessionStorage.getItem('user_id');

                    try {
                        await Promise.all([
                            publicService.saveLeadProduct({
                                name: cardData.name,
                                creditLimitTo: cardData.creditLimitTo,
                                creditLimitFrom: cardData.creditLimitFrom,
                                annualFee: cardData.annualFee,
                                cashAdvanceFee: cardData.cashAdvanceFee,
                                monthlyIncome: cardData.monthlyIncome,
                                requiredAge: cardData.requiredAge,
                                features: cardData.features,
                                interestRate: cardData.interestRate,
                                uuid: UUID,
                            }),
                            publicService.applyRegister(step, type, leadDto)
                        ]);

                        console.log("Cả hai hàm đã được thực thi thành công");
                    } catch (error) {
                        console.error("Có lỗi xảy ra khi thực thi:", error);
                    }

                    if (hasOtpSub) {
                        history.push({
                            pathname: `/success`,
                            state: {partner: partner},
                            search: `?partner=${partner}`,
                        });
                    } else {
                        history.push({
                            pathname: `/receive-OTP/${type}`,
                            state: {
                                cardData: cardData,
                                phone: phone,
                                emailUsername: emailUsername,
                                emailDomain: emailDomain,
                                partner: partner
                            },
                            search: `?partner=${partner}`,
                        });
                    }

                    return;
                } catch (error: any) {
                    errorNotify.error({
                        message: 'Lỗi',
                        description: error.response?.data || 'An error occurred',
                    });
                } finally {
                    setLoading(false);
                }
            }
        }

        setLoading(true);
        try {
            const resp = await publicService.applyRegister(step, type, leadDto);

            if (resp.status === 200) {
                if (type === 'credit-card') {
                    history.push(`/danh-sach-the/${type}`, {
                        listCard: resp.data.creditCards,
                        filterType: resp.data.filterType,
                        phone: phone,
                        emailUsername: emailUsername,
                        emailDomain: emailDomain,
                    });
                } else {
                    sessionStorage.removeItem('cardData');

                    await callReceiver();

                    const selectedLoanPackage = JSON.parse(sessionStorage.getItem('selectedLoanPackage') || '{}');
                    const loanPackage = JSON.parse(sessionStorage.getItem('loanPackage') || '{}');
                    const selectedLoanData = JSON.parse(sessionStorage.getItem('loanData') || '{}');
                    const userUUID = sessionStorage.getItem('user_id');

                    const loanData = {
                        ...selectedLoanPackage,
                        ...loanPackage,
                        ...selectedLoanData,
                        uuid: userUUID,
                    };

                    const response = await publicService.saveLeadProduct(loanData);

                    if (response.status === 200) {
                        if (hasOtpSub) {
                            if (partner) {
                                history.push({
                                    pathname: '/success-view',
                                    state: {loanData: loanData},
                                    search: `?partner=${partner}`,
                                });
                            } else {
                                history.push('/success-view', {loanData: loanData});
                            }
                        } else {
                            if (partner) {
                                history.push({
                                    pathname: `/receive-OTP/${type}`,
                                    state: {
                                        loanData: loanData,
                                        phone: phone,
                                        emailUsername: emailUsername,
                                        emailDomain: emailDomain,
                                    },
                                    search: `?partner=${partner}`,
                                });
                            } else {
                                history.push(`/receive-OTP/${type}`,
                                    {
                                        loanData: loanData,
                                        phone: phone,
                                        emailUsername: emailUsername,
                                        emailDomain: emailDomain,
                                    });
                            }
                        }
                    }
                }
            }
        } catch (error: any) {
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    };

    const cityOptions = Object.entries(cities).map(([code, city]) => ({
        code: code,
        value: city.name_with_type,
        label: city.name_with_type
    })).sort((a, b) => a.label.localeCompare(b.label));

    const districtOptions = Object.entries(districts)
        .filter(([, district]) => district.parent_code === contactInfo.cityCode)
        .map(([, district]) => ({
            value: district.name_with_type,
            label: district.name_with_type
        })).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            {contextHolder}
            <div className="mx-3 mt-4 personal-info-form">
                <Spin spinning={loading}>
                    <p style={{fontWeight: '1000', marginBottom: '0.2em'}} className="fs-4 title-page">
                        Thêm thông tin cá nhân để chúng tôi tìm đối tác phù hợp cho bạn
                    </p>
                    <div style={{marginTop: '50px'}}>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}} className="fw-semibold m-0 svn-font">
                                Ngày sinh
                            </p>
                            <input
                                type="date"
                                value={contactInfo.dob}
                                onChange={handleDateChange}
                                placeholder="Chọn ngày sinh"
                                className="input-date"
                            />
                        </div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}} className="fw-semibold m-0 svn-font">Địa
                                chỉ</p>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <Select
                                        options={cityOptions}
                                        onChange={handleCityChange}
                                        value={cityOptions.find(option => option.value === contactInfo.selectedCity)}
                                        placeholder="Chọn Tỉnh/Thành"
                                        className="basic-single"
                                    />
                                </div>
                                <div className="col-6">
                                    <Select
                                        className="basic-single"
                                        options={districtOptions}
                                        onChange={handleDistrictChange}
                                        value={contactInfo.selectedDistrict ? districtOptions.find(option => option.value === contactInfo.selectedDistrict) : null} // Updated value
                                        placeholder="Chọn Quận/Huyện"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}}
                               className="fw-semibold m-0 svn-font">Địa chỉ cụ thể</p>
                            <input
                                type="text"
                                className="form-control border border-black"
                                id="address"
                                placeholder="Vui lòng nhập"
                                value={contactInfo.inputAddress}
                                onChange={handleAddressChange}
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn-confirm" onClick={handleSubmit}>
                                {type === 'credit-card' ? 'Tìm thẻ phù hợp' : 'Tìm khoản vay phù hợp'}
                            </button>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button type="submit" className="btn-skip" onClick={handleSubmitSkip}>
                                Bỏ qua
                            </button>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default React.memo(PersonalInfoPageView);
import React, {useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import './ListCardPageView1.scss';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';
import {Spin, Select} from "antd";

const { Option } = Select;

interface CreditCard {
    id: number;
    name: string;
    iconUrl: string;
    imageUrl: string;
    creditLimitFrom: number;
    creditLimitTo: number;
    annualFee: number;
    monthlyIncome: number;
    partner: string;
    navigateLink: string;
    cardType: string;
    interestRate: string;
}

const getAllCreditCard = async (partner?: string) => {
    try {
        if (partner) {
            document.body.classList.add(`partner-${partner}`)
            const resp = await publicService.getAllCreditCardByPartner(partner);
            return resp.data;
        } else {
            document.body.classList.add(':root');
            const resp = await publicService.getAllCreditCard();
            return resp.data;
        }
    } catch (error) {
        console.error('Error fetching credit card data:', error);
        return [];
    }
}

const ListCardPageView1: React.FC = () => {
    const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
    const [loading, setLoading] = useState(false);
    const [visibleCount, setVisibleCount] = useState(3);
    const [banner, setBanner] = useState<string | null>(null);
    const [colorBody, setColorBody] = useState<string>('#eefd8c');
    const [selectedCardType, setSelectedCardType] = useState<string>('');
    const [selectedIssuer, setSelectedIssuer] = useState<string>('');
    const [cardTypes, setCardTypes] = useState<string[]>([]);
    const [issuers, setIssuers] = useState<string[]>([]);

    const history = useHistory();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const partner = searchParams.get('partner') || '';

    useEffect(() => {
        document.title = "Gocard | Thẻ tín dụng"

        const currentUrl = window.location.href;
        const subOtp = searchParams.get('sub');

        sessionStorage.setItem('link', currentUrl);
        sessionStorage.setItem('user_id', crypto.randomUUID());

        if (subOtp === 'otp') {
            sessionStorage.setItem('link', currentUrl);

            const url = new URL(window.location.href);
            url.searchParams.delete('sub');

            window.history.replaceState({}, document.title, url.toString());
        }

        const fetchPartnerCss = async (partner: string | null) => {
            if (partner) {
                try {
                    const resp = await publicService.getPartnerCssByProductPartner(partner);
                    const data = resp.data;

                    setBanner(data.bannerPartnerUrl);
                    setColorBody(data.colorBody);
                } catch (err) {
                    console.log('Error', err);
                }
            }

        }

        const fetchData = async () => {
            setLoading(true);
            let data = await getAllCreditCard(partner);

            const uniqueCardTypes = Array.from(new Set(data.map((card: CreditCard) => card.cardType))) as string[];
            const uniqueIssuers = Array.from(new Set(data.map((card: CreditCard) => card.partner))) as string[];

            setCardTypes(uniqueCardTypes);
            setIssuers(uniqueIssuers);

            if (partner === 'vib') {
                data = data.sort((a: CreditCard, b: CreditCard) => {
                    if (a.name === "VIB Online Plus 2in1") return -1;
                    if (b.name === "VIB Online Plus 2in1") return 1;
                    return 0;
                });
            } else if (partner === 'vpbank') {
                data = data.sort((a: CreditCard, b: CreditCard) => {
                    if (a.name === "VPBank StepUP MasterCard") return -1;
                    if (b.name === "VPBank StepUP MasterCard") return 1;
                    if (a.name === "Shopee Platinum") return -1;
                    if (b.name === "Shopee Platinum") return 1;
                    return 0;
                })
            }

            setCreditCards(data);
            setLoading(false);
        };

        fetchPartnerCss(partner);
        fetchData();
        window.scroll(0, 0);
    }, [partner]);

    const filterCreditCards = (cards: CreditCard[]) => {
        let filteredCards = cards;

        if (selectedCardType) {
            filteredCards = filteredCards.filter((card) =>
                card.cardType.toLowerCase() === selectedCardType.toLowerCase()
            );
        }

        if (selectedIssuer) {
            filteredCards = filteredCards.filter((card) =>
                card.partner.toLowerCase() === selectedIssuer.toLowerCase()
            );
        }

        return filteredCards;
    };

    const handleCardTypeChange = (value: string) => {
        setSelectedCardType(value);
    };

    const handleIssuerChange = (value: string) => {
        setSelectedIssuer(value);
    };

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    useEffect(() => {
        setVisibleCount(3);
    }, [selectedCardType, selectedIssuer]);

    const handleDetailCard = (cardItem: CreditCard) => {
        setLoading(true);
        try {
            if (partner) {
                history.push({
                    pathname: '/chi-tiet-the',
                    state: {cardItem: cardItem, partner: partner},
                    search: `?partner=${partner}`
                });
            } else {
                history.push({
                    pathname: '/chi-tiet-the',
                    state: {cardItem: cardItem, partner: partner}
                });
            }
        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false);
        }
    }

    const handleCreateCard = async (step: string, type: string, cardItem: CreditCard) => {
        setLoading(true);
        try {
            const token = crypto.randomUUID();
            sessionStorage.setItem('token', token);

            const cardData = {
                product: cardItem.name,
                creditLimit: cardItem.creditLimitTo,
                monthlyIncome: cardItem.monthlyIncome,
            };

            sessionStorage.setItem('cardData', JSON.stringify(cardItem));

            const response = await publicService.applyRegister(step, type, cardData);
            if (response.status === 200) {
                if (partner) {
                    history.push({
                        pathname: '/contact-info/credit-card',
                        state: {cardItem: cardData, partner: partner},
                        search: `partner=${partner}`
                    })
                } else {
                    history.push('/contact-info/credit-card', {cardItem: cardData});
                }
            } else {
                console.error('Đăng ký thẻ tín dụng thất bại:', response);
            }
        } catch (error) {
            console.error('Lỗi khi đăng ký thẻ tín dụng:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSeeMore = () => {
        setVisibleCount((prevCount) => prevCount + 3);
    };

    const filteredCards = filterCreditCards(creditCards);

    return (
        <>
            <Spin spinning={loading}>
                <div className='container'>
                    {banner && (
                        <div className="banner-container">
                            <div>
                                <img src={banner} alt="Partner Banner"/>
                            </div>
                        </div>
                    )}

                    <div className="filter-container">
                        <div className="filter__select">
                            <h4>Loại thẻ</h4>
                            <Select
                                style={{width: '100%'}}
                                placeholder="Chọn loại thẻ"
                                value={selectedCardType}
                                onChange={handleCardTypeChange}
                            >
                                <Option value="">Tất cả</Option>
                                {cardTypes.map((type, index) => (
                                    <Option key={index} value={type}>{type}</Option>
                                ))}
                            </Select>
                        </div>

                        <div className="filter__select">
                            <h4>Đơn vị phát hành</h4>
                            <Select
                                style={{width: '100%'}}
                                placeholder="Chọn đơn vị phát hành"
                                value={selectedIssuer}
                                onChange={handleIssuerChange}
                            >
                                <Option value="">Tất cả</Option>
                                {issuers.map((issuer, index) => (
                                    <Option key={index} value={issuer.toLowerCase()}>{issuer}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <h1 className='text-list-card'>DANH SÁCH THẺ TÍN DỤNG</h1>
                    <div className='list'>
                        {filteredCards.slice(0, visibleCount).map((card: CreditCard) => (
                            <div className='list-card mb-3' key={card.id} style={{backgroundColor: colorBody}}>
                                <div
                                    className={`card-icon ${partner === 'vpbank' || card.partner.toLowerCase().includes('vpbank') ? 'vp-icon'
                                        : partner === 'tpbank' || card.partner.toLowerCase().includes('tp') ? 'tp-icon'
                                            : partner === 'vib' || card.partner.toLowerCase().includes('vib') ? 'vib-icon' : ''}`}>
                                    <img src={card.iconUrl} alt="card-icon"/>
                                </div>
                                <span className='pt-2 text-bold card-name'>{card.name}</span>
                                <div className='card-open row'>
                                    <div className="col-6">
                                        <img src={card.imageUrl}
                                             className={
                                                 card.name.toLowerCase().includes('tpbank evo') ? 'tp-img' :
                                                     card.name.toLowerCase().includes('vpbank z') ? 'vp-img' :
                                                         card.name.toLowerCase().includes('super shopee platinum') ? 'vp-img' : ''
                                             }
                                             alt="card"/>
                                    </div>
                                    <div
                                        className="col-6 d-flex flex-column justify-content-center align-items-center open-card">
                                        <button className="mb-2"
                                                onClick={() => handleCreateCard("dang-ky-the-tin-dung", 'credit-card', card)}>
                                            Mở thẻ ngay
                                        </button>
                                        <a onClick={() => handleDetailCard(card)}>Tìm hiểu thêm &gt;</a>
                                    </div>
                                </div>
                                <div className='credit-limit'>
                                    <span className='span-title'>Loại thẻ:</span>
                                    <span className="span-content">
                                  {card.cardType}
                                </span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Phí thường niên:</span>
                                    <span className="span-content">
                                    {formatNumberCustom(card.annualFee)}
                                </span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Lãi suất năm:</span>
                                    <span className="span-content">{card.interestRate}</span>
                                </div>
                            </div>
                        ))}
                        {visibleCount < filteredCards.length && (
                            <a className='see-more' onClick={handleSeeMore}>Xem thêm -&gt;</a>
                        )}
                    </div>
                    <ScrollToTopButton/>
                </div>
            </Spin>
        </>
    );
}

export default React.memo(ListCardPageView1);
